$primary: #524939;
$secondary: #FA58B6;
$black10: rgba(28, 29, 34, 1);
$black08: rgba(28, 29, 34, 0.8);
$black06: rgba(28, 29, 34, 0.6);
$black01: rgba(28, 29, 34, 0.1);
$login: #323377;
$positive: rgba(81, 156, 102,1);
$negative: rgba(204, 95, 95,1);
$white: white;

@keyframes slideInFromLeft {
    0% {
      transform: translateX(2%);
    }
    100% {
      transform: translateX(0);
    }
  }
  @keyframes slideInFromUp {
    0% {
      transform: translateY(20%);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
     }
  }

  .fadeIn{
    animation: fadeInAnimation ease 0.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
  }
  .slideleft{
    animation: 1s ease-in-out 0s 1 slideInFromLeft;
  }
  .slideup{
    animation: 1s ease-in-out 0s 1 slideInFromUp;
  }
  @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap');
  
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins',sans-serif;
    scrollbar-width: thin;
    scrollbar-color: #2e2e2e #0a1119;
    scroll-behavior: smooth;
    scroll-padding: 100px;
  }
  
  html{
    height: 100%;
    scrollbar-width: thin;
    scrollbar-color: #2e2e2e #0a1119;
    scroll-behavior: smooth;
    scroll-padding: 100px;
  }

  body{
    height: 100%;
    background-color: $primary;
    overflow-x: hidden;
    overflow-y:auto;
    color: $white;
    scrollbar-width: thin;
    scrollbar-color: #2e2e2e #0a1119;
    scroll-behavior: smooth;
    scroll-padding: 100px;
  }

  ::selection{
    background: rgba(28, 21, 59, 0.162);
  }

  img{
      user-select: none;
      -webkit-user-drag: none;
  }
  
  a, a:hover{
    text-decoration: none;
    color: inherit;
  }

  #app{
    nav{
      transition: 0.2s;
      background: rgb(1,1,1) !important;
      background: -moz-linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(1,1,1,0.804359243697479) 16%, rgba(1,1,1,0.6867121848739496) 32%, rgba(1,1,1,0.5158438375350141) 53%, rgba(1,1,1,0.2553396358543417) 72%, rgba(1,1,1,0.13489145658263302) 84%, rgba(1,1,1,0) 100%) !important;
      background: -webkit-linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(1,1,1,0.804359243697479) 16%, rgba(1,1,1,0.6867121848739496) 32%, rgba(1,1,1,0.5158438375350141) 53%, rgba(1,1,1,0.2553396358543417) 72%, rgba(1,1,1,0.13489145658263302) 84%, rgba(1,1,1,0) 100%) !important;
      background: linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(1,1,1,0.804359243697479) 16%, rgba(1,1,1,0.6867121848739496) 32%, rgba(1,1,1,0.5158438375350141) 53%, rgba(1,1,1,0.2553396358543417) 72%, rgba(1,1,1,0.13489145658263302) 84%, rgba(1,1,1,0) 100%) !important;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#010101",endColorstr="#010101",GradientType=1);
      .logo{
        max-width: 100px;
      }
      .dalosnetwork{
        max-width: 250px;
      }
    }

    %bg{
        background-position: top;
        background-repeat: no-repeat;
        background-size: cover;
    }
    #home{
        @extend %bg;
        color: $primary;
        background-image: url("assets/backgrounds/home.png");
        margin-top: -6rem;
        height: 101vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .homeWrapper{
            position: relative;
            height: 66vh;
            display: flex;
            justify-content: center;
            .homeText{
                text-align: center;
                position: absolute;
                bottom: 0;
                p{
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 17.91px;
                    text-align: left;

                }
                button{
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 23.71px;
                    text-align: left;
                    color: $primary;
                    padding: 10px 16px;
                    border-radius: 31px;
                    background: linear-gradient(180deg, #FFFAED -27.17%, #FFFFFE 29.45%, #FFFCF1 67.08%, #FBE6CF 100%);
                }
            }
        }
    }
    #aboutus{
        @extend %bg;
        color: $primary;
        background-image: url("assets/backgrounds/about.png");
        height: 125vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .ab{
          margin-top: 23rem;
        }
        .aboutWrapper{
          height: 37vh;
          .aboutText{
              img{
                max-width: 100px;
              }
          }
        }
    }

    #services{
        @extend %bg;
        color: $white;
        background-image: url("assets/backgrounds/service2.png");
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .serviceWrapper{
          position: relative;
          height: 61vh;
          display: flex;
          justify-content: center;
          .serviceText{
              position: absolute;
              top: 0;
              .serviceHeader{
                font-family: 'Times New Roman', Times, serif;
                font-size: 57px;
                font-weight: 400;
                line-height: 75.98px;
              }
              h5{
                font-family: 'Times New Roman', Times, serif;
                font-size: 38px;
                font-weight: 400;
                line-height: 50.65px;             
              }
              p{
                font-size: 18px;
                font-weight: 400;
                line-height: 17.91px;
              }
              .sr{
                padding: 0 18rem;
              }
              .sr1{
                background: none;
              }
          }
        }
    }
    #contact{

    }
    #footer{
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        color: $white;
        background-image: url("assets/backgrounds/footer.png");
        height: 90vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .footerWrapper{
          position: relative;
          height: 79vh;
          display: flex;
          justify-content: center;
          .footerText{
              position: absolute;
              top: 0;
              .footerLogo{
                max-width: 300px;
              }
              .footerDalos{
                max-width: 300px;
              }
              .footerIcon{
                font-size: 2rem;
                margin: auto 1rem;
              }
              .footerMail{
                font-size: 18px;
                font-weight: 300;
                line-height: 17.78px;
              }
          }
        }
    }
  }

  @media only screen and (max-width: 1400px) {
    #app{
      nav{
      }
  
      #home{
      }

      #aboutus{
          color: $primary;
          background-image: url("assets/backgrounds/about.png");
          height: 125vh;
          display: flex;
          align-items: center;
          justify-content: center;
          .ab{
            margin-top: 8rem;
          }
          .aboutWrapper{
            height: 27vh;
            .aboutText{
                img{
                  max-width: 100px;
                }
            }
            .swiper-slide{
              padding: 0 3rem;
            }
          }
      }
  
      #services{
          color: $white;
          background-image: url("assets/backgrounds/service2.png");
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          .serviceWrapper{
            position: relative;
            height: 85vh;
            display: flex;
            justify-content: center;
            .serviceText{
                position: absolute;
                top: 0;
                .serviceHeader{
                  font-family: 'Times New Roman', Times, serif;
                  font-size: 57px;
                  font-weight: 400;
                  line-height: 75.98px;
                }
                h5{
                  font-family: 'Times New Roman', Times, serif;
                  font-size: 38px;
                  font-weight: 400;
                  line-height: 50.65px;             
                }
                p{
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 17.91px;
                }
            }
          }
      }
      #contact{
  
      }
      #footer{
        height: 90vh;
        .footerWrapper{
          position: relative;
          height: 79vh;
          display: flex;
          justify-content: center;
          .footerText{
              position: absolute;
              top: 0;
              .footerLogo{
                max-width: 200px;
              }
              .footerDalos{
                max-width: 200px;
              }
              .footerIcon{
                font-size: 1.5rem;
                margin: auto 1rem;
              }
              .footerMail{
                font-size: 18px;
                font-weight: 300;
                line-height: 17.78px;
              }
          }
        }
    }
    }
  }

  @media only screen and (max-width: 992px) {
    #app{
      nav{
        background: rgb(1,1,1) !important;
        background: -moz-linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(1,1,1,0.804359243697479) 16%, rgba(1,1,1,0.6867121848739496) 32%, rgba(1,1,1,0.5158438375350141) 53%, rgba(1,1,1,0.2553396358543417) 72%, rgba(1,1,1,0.13489145658263302) 84%, rgba(1,1,1,0) 100%) !important;
        background: -webkit-linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(1,1,1,0.804359243697479) 16%, rgba(1,1,1,0.6867121848739496) 32%, rgba(1,1,1,0.5158438375350141) 53%, rgba(1,1,1,0.2553396358543417) 72%, rgba(1,1,1,0.13489145658263302) 84%, rgba(1,1,1,0) 100%) !important;
        background: linear-gradient(180deg, rgba(1,1,1,1) 0%, rgba(1,1,1,0.804359243697479) 16%, rgba(1,1,1,0.6867121848739496) 32%, rgba(1,1,1,0.5158438375350141) 53%, rgba(1,1,1,0.2553396358543417) 72%, rgba(1,1,1,0.13489145658263302) 84%, rgba(1,1,1,0) 100%) !important;
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#010101",endColorstr="#010101",GradientType=1);
        .logo{
          max-width: 100px;
        }
        .dalosnetwork{
          display: none;
        }
        .navbar-toggler{
          display: none;
        }
      }
  
      #home{
      }
      #aboutus{
          color: $primary;
          background-image: url("assets/backgrounds/about.png");
          height: 125vh;
          display: flex;
          align-items: center;
          justify-content: center;
          .aboutWrapper{
            height: 27vh;
            .aboutText{
                p{
                  font-size: .9rem;
                }
                img{
                  max-width: 100px;
                }
            }
            .swiper-slide{
              padding: 0 3rem;
            }
          }
      }
  
      #services{
          color: $white;
          background-image: url("assets/backgrounds/service2.png");
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          .serviceWrapper{
            position: relative;
            height: 85vh;
            display: flex;
            justify-content: center;
            .serviceText{
                position: absolute;
                top: 0;
                .serviceHeader{
                  font-family: 'Times New Roman', Times, serif;
                  font-size: 57px;
                  font-weight: 400;
                  line-height: 75.98px;
                }
                h5{
                  font-family: 'Times New Roman', Times, serif;
                  font-size: 38px;
                  font-weight: 400;
                  line-height: 50.65px;             
                }
                p{
                  font-size: 18px;
                  font-weight: 400;
                  line-height: 17.91px;
                }
                .sr{
                  padding: 0 3rem;
                }
                .sr1{
                  background: rgba(0, 2, 10, 0.623);
                }
            }
          }
      }
      #contact{
  
      }
      #footer{
      }
    }
  }